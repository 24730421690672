import { DropdownItem } from '@appfolio/react-gears';
import { isEqual as equal } from 'lodash';
import React from 'react';
import { Option, OptionGroup, isOptionGroup, makeKey } from './Combobox';

export interface DropdownOptionsProps<T> {
  options: (Option<T> | OptionGroup<T>)[];
  visibleOptions: Option<T>[];
  focusedOptionIndex: number;
  setFocusedOptionIndex: (index: number) => void;
  selectOption: (value: T, label: string) => void;
  focusedOption: React.MutableRefObject<any>;
  value: T | undefined;
}

function DropdownOptions<T>({
  options,
  visibleOptions,
  focusedOptionIndex,
  setFocusedOptionIndex,
  selectOption,
  focusedOption,
  value,
}: DropdownOptionsProps<T>) {
  const isOptionVisible = (option: Option<T>) =>
    visibleOptions.indexOf(option) > -1;

  const isOptionSelected = (option: Option<T>) => equal(value, option.value);

  const renderOptions = (opts: Option<T>[], isNested: boolean) =>
    opts.map(option => {
      const visibleIndex = visibleOptions.indexOf(option);
      const key = makeKey(option.value);
      return (
        <DropdownItem
          style={{ paddingLeft: isNested ? '30px' : '15px' }}
          disabled={option.disabled}
          className={`${isOptionVisible(option) ? '' : 'visually-hidden'}`}
          key={key}
          id={`option-${key}`}
          active={focusedOptionIndex === visibleIndex}
          onMouseEnter={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            setFocusedOptionIndex(visibleIndex);
          }}
          onKeyDown={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              ev.stopPropagation();
              selectOption(option.value, option.label);
            }
          }}
          onMouseDown={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            selectOption(option.value, option.label);
          }}
          ref={visibleIndex === focusedOptionIndex ? focusedOption : null}
          role="option"
          aria-selected={isOptionSelected(option)}
        >
          {option.label}
        </DropdownItem>
      );
    });

  const renderGroupedOption = (group: OptionGroup<T>, groupIndex: number) => {
    const hasVisibleOptions = group.options.some(option => {
      return visibleOptions.some(visible => visible.value === option.value);
    });

    if (!hasVisibleOptions) {
      return null;
    }
    return (
      <React.Fragment key={`group-${group.label}-${groupIndex}`}>
        <DropdownItem
          header
          style={{
            fontWeight: 'bold',
            color: '#333',
            fontSize: '1rem',
            paddingLeft: '15px',
          }}
          key={`group-${group.label}`}
        >
          {group.label}
        </DropdownItem>
        {renderOptions(group.options, true)}
      </React.Fragment>
    );
  };

  return (
    <>
      {options.map((option, index) => {
        if (isOptionGroup(option)) {
          return renderGroupedOption(option, index);
        }
        return renderOptions([option], false);
      })}
    </>
  );
}

export default DropdownOptions;
